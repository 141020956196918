import { useState } from 'react';
import emailjs from 'emailjs-com';
import { BiMap } from "react-icons/bi";
import { BsEnvelope, BsTelephoneOutboundFill } from "react-icons/bs";
import { FaInstagram, FaLinkedin, FaTiktok } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import footerlogo from '../../../src/Assets/images/logo/Blue Minimalist Investment Company Logo.png';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubscribe = (e) => {
    e.preventDefault();
    
    if (!email) {
      setMessage('Please enter a valid email address.');
      return;
    }

    const templateParams = {
      to_email: 'talharao944@gmail.com', // The email address to send to
      from_email: email, // The email address from which the subscription request came
      message: `Hello there, I subscribe your website with the email: ${email}`
    };

    emailjs.send('service_c6dlw1v', 'template_iuvc8b7', templateParams, 'xl3P3SOjfCy-_DPZS')
      .then(response => {
        setMessage('Subscription successful!');
        setEmail('');
      })
      .catch(error => {
        setMessage('Subscription failed. Please try again later.');
      });
  };

  return (
    <footer className="text-slate-200 py-8">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-wrap gap-8 md:gap-16">
          {/* Logo Section */}
          <div className="md:flex-1 flex justify-center items-center">
            <img src={footerlogo} alt="Footer Logo" className="w-20 md:w-20" />
          </div>

          {/* Social Media Links */}
          <div className="flex-1">
            <h2 className="text-xl font-semibold mb-4">Social Handles</h2>
            <div className="flex gap-4">
              <a href="#" className="icon-box bg-dark-light hover:bg-hover-color-dark">
                <FiFacebook className="text-2xl" />
              </a>
              <a href="#" className="icon-box bg-dark-light hover:bg-hover-color-dark">
                <FaTiktok className="text-2xl" />
              </a>
              <a href="#" className="icon-box bg-dark-light hover:bg-hover-color-dark">
                <FaInstagram className="text-2xl" />
              </a>
              <a href="#" className="icon-box bg-dark-light hover:bg-hover-color-dark">
                <FaLinkedin className="text-2xl" />
              </a>
            </div>
          </div>

          {/* Contact Information */}
          <div className="flex-1">
            <h2 className="text-xl font-semibold mb-4">Contact</h2>
            <ul className="space-y-3">
              <li className="text-muted flex items-center gap-2">
                <BiMap className="text-primary text-lg" />
                <span>Business Wall, Dubai, UAE</span>
              </li>
              <li className="text-muted flex items-center gap-2">
                <BsTelephoneOutboundFill className="text-primary text-lg" />
                <span>+123 345 56666666</span>
              </li>
              <li className="text-muted flex items-center gap-2">
                <BsEnvelope className="text-primary text-lg" />
                <span>abc@gmail.com</span>
              </li>
            </ul>
          </div>

          {/* Newsletter Subscription */}
          <div className="flex-1 text-center md:text-left">
            <h2 className="text-xl font-semibold mb-4">Subscribe to our Newsletter</h2>
            <p className="text-sm text-muted mb-4">
              Subscribe to be the first one to know about updates. Enter your email below:
            </p>
            <form onSubmit={handleSubscribe} className="flex flex-col md:flex-row items-center gap-4">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="px-4 py-2 bg-transparent border border-dark rounded-lg outline-none"
                placeholder="Email Address..."
              />
              <button type="submit" className="btn btn-primary mt-2 md:mt-0">Subscribe</button>
            </form>
            {message && <p className="mt-4 text-sm">{message}</p>}
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="text-center border-t border-dark pt-4 mt-6 text-sm">
          <p>
            Created By <span className="text-primary">Seaboard Group</span> | All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
