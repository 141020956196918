import img1 from '../Assets/images1/images/property (1).jpeg';
import img2 from '../Assets/images1/images/property (1).jpg';
import img3 from '../Assets/images1/images/property (10).jpg';
import img4 from '../Assets/images1/images/property (11).jpg';
import img5 from '../Assets/images1/images/property (12).jpg';
import img6 from '../Assets/images1/images/property (13).jpg';
import img7 from '../Assets/images1/images/property (14).jpg';
import img8 from '../Assets/images1/images/property (15).jpg';
import img9 from '../Assets/images1/images/property (16).jpg';
import img10 from '../Assets/images1/images/property (17).jpg';
import img11 from '../Assets/images1/images/property (18).jpg';
import img12 from '../Assets/images1/images/property (19).jpg';
import img13 from '../Assets/images1/images/property (2).jpeg';
import img14 from '../Assets/images1/images/property (2).jpg';
import img15 from '../Assets/images1/images/property (20).jpg';
import img16 from '../Assets/images1/images/property (21).jpg';
import img17 from '../Assets/images1/images/property (22).jpg';
import img18 from '../Assets/images1/images/property (23).jpg';
import img19 from '../Assets/images1/images/property (24).jpg';
import img20 from '../Assets/images1/images/property (25).jpg';
import img21 from '../Assets/images1/images/property (26).jpg';
import img22 from '../Assets/images1/images/property (27).jpg';
import img23 from '../Assets/images1/images/property (28).jpg';
import img24 from '../Assets/images1/images/property (29).jpg';
import img25 from '../Assets/images1/images/property (3).jpeg';
import img26 from '../Assets/images1/images/property (3).jpg';
import img27 from '../Assets/images1/images/property (30).jpg';
import img28 from '../Assets/images1/images/property (31).jpg';
import img29 from '../Assets/images1/images/property (32).jpg';
import img30 from '../Assets/images1/images/property (33).jpg';
import img31 from '../Assets/images1/images/property (34).jpg';
import img32 from '../Assets/images1/images/property (35).jpg';
import img33 from '../Assets/images1/images/property (36).jpg';
import img34 from '../Assets/images1/images/property (37).jpg';
import img35 from '../Assets/images1/images/property (38).jpg';
import img36 from '../Assets/images1/images/property (39).jpg';
import img37 from '../Assets/images1/images/property (4).jpeg';
import img38 from '../Assets/images1/images/property (4).jpg';
import img39 from '../Assets/images1/images/property (40).jpg';
import img40 from '../Assets/images1/images/property (41).png';
import img41 from '../Assets/images1/images/property (5).jpg';
import img42 from '../Assets/images1/images/property (6).jpeg';
// import img43 from '../Assets/images1/images/property (6).jpg';
import img44 from '../Assets/images1/images/property (7).jpg';
import img45 from '../Assets/images1/images/property (8).jpg';
import img46 from '../Assets/images1/images/property (9).jpg';


import {

  BiBuildings,
  BiFullscreen,
  BiHomeAlt,
  BiBarChart,
  BiMoney,
  BiShieldAlt2,
} from "react-icons/bi";
// import { BsStarFill } from "react-icons/bs";
import {
  FaBehance,
  FaDribbble,
  FaFacebook,
  FaInstagram,
  FaLinkedin,

  FaTwitter,
  FaVimeo,
  FaYoutube,
} from "react-icons/fa";


export const property = [
  {
    id: 1,
    name: "Luxury Palm Villa Condos",
    location: "Dubai, Palm Jumeirah, Frond M",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",

    price: 2500,
    distance: "2km",
    purpose: "Sale",
    number_of_beds: 5,
    number_of_bathrooms: 6,
    propertyType: "condos",
    contact: "+971 50 1234567",
    dimensions: "6000 sq ft",
    dealerImage: "",
    dd_MM_yy: "Day",
    dealer: "Aisha Khan",
    description: "Stunning luxury villa located on the iconic Palm Jumeirah. This property features spacious living areas, private beach access, and panoramic views of the Dubai skyline. Ideal for those seeking opulent living with top-notch amenities.",
    // image: img1,
     image: [
    img1,
    img2,
    img3
  ]
  },
  {
    id: 2,
    name: "Modern Downtown Apartment Condos",
    location: "Dubai, Downtown Dubai, Burj Khalifa View",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 1800,
    distance: "1km",
    purpose: "Sale",
    number_of_beds: 2,
    number_of_bathrooms: 2,
    propertyType: "condos",
    contact: "+971 50 2345678",
    dimensions: "1500 sq ft",
    dealerImage: "",
    dd_MM_yy: "Week",
    dealer: "Omar Al-Farsi",
    description: "Elegant apartment in the heart of Downtown Dubai with breathtaking views of the Burj Khalifa. Features include contemporary design, state-of-the-art facilities, and close proximity to shopping and dining options.",
    // image: "/images/property (26).jpg",
     image: [
    img4,
    img5,
    img6,
    img7,
  ]
  },
  {
    id: 3,
    name: "Cozy Dubai Marina Condos",
    location: "Dubai, Dubai Marina, Marina Heights",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 1500,
    distance: "500m",
    purpose: "Rent",
    number_of_beds: 2,
    number_of_bathrooms: 2,
    propertyType: "condos",
    contact: "+971 50 3456789",
    dimensions: "1200 sq ft",
    dealerImage: "",
    dd_MM_yy: "Year",
    dealer: "Sarah Ali",
    description: "Charming  in the vibrant Dubai Marina. Enjoy waterfront living with easy access to the Marina Walk, luxury shopping, and fine dining. Perfect for those who appreciate modern comforts and an active lifestyle.",
    // image: "/images/property (27).jpg",
     image: [
      img8,
    img9,
    img10,
    img11,
  ]
  },
  {
    id: 4,
    name: "Spacious Jumeirah Beach Villa Condos",
    location: "Dubai, Jumeirah Beach, Beachfront",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 3000,
    distance: "0.5km",
    purpose: "Sale",
    number_of_beds: 6,
    number_of_bathrooms: 7,
    propertyType: "condos",
    contact: "+971 50 4567890",
    dimensions: "8000 sq ft",
    dealerImage: "",
    dd_MM_yy: "Month",
    dealer: "Mohammed Rashid",
    description: "Luxurious beachfront villa offering unrivaled views of the Arabian Gulf. This property features expansive living spaces, private pool, and direct beach access. An exquisite choice for those seeking the ultimate in Dubai's luxury living.",
    // image: "/images/property (28).jpg",
     image: [
      img12,
      img13,
      img14,
      img15,
  ]
  },
  {
    id: 5,
    name: "Elegant Business Bay Shop",
    location: "Dubai, Business Bay, Bay Square",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 2000,
    distance: "1.5km",
    purpose: "Rent",
    number_of_beds: 0,
    number_of_bathrooms: 1,
    propertyType: "shop",
    contact: "+971 50 5678901",
    dimensions: "1000 sq ft",
    dealerImage: "",
    dd_MM_yy: "Day",
    dealer: "Fatima Al-Mansoori",
    description: "Modern  space in Business Bay, offering a prime location with stunning city views. Ideal for businesses looking to establish a presence in Dubai's financial district. Features include high-speed internet and flexible layout options.",
    // image: "/images/property (29).jpg",
     image: [
      img16,
      img17,
      img18,
      img19,
      img20,
  ]
  },
  {
    id: 6,
    name: "Stylish Al Barsha Villa Shop",
    location: "Dubai, Al Barsha, Al Barsha South",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 2200,
    distance: "3km",
    purpose: "Sale",
    number_of_beds: 4,
    number_of_bathrooms: 5,
    propertyType: "shop",
    contact: "+971 50 6789012",
    dimensions: "5000 sq ft",
    dealerImage: "",
    dd_MM_yy: "Week",
    dealer: "Noura Al-Sheikh",
    description: "Contemporary villa in the desirable Al Barsha neighborhood. Features include spacious rooms, a private garden, and close proximity to schools and shopping centers. A great option for families seeking comfort and convenience.",
    // image: "/images/property (30).jpg",
     image: [
      img21,
      img22,

  ]
  },
  {
    id: 7,
    name: "Luxury Desert Retreat appartments Shop",
    location: "Dubai, Al Marmoom, Desert Oasis",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 3500,
    distance: "20km",
    purpose: "Rent",
    number_of_beds: 4,
    number_of_bathrooms: 4,
    propertyType: "shop",
    contact: "+971 50 7890123",
    dimensions: "7000 sq ft",
    dealerImage: "",
    dd_MM_yy: "Month",
    dealer: "Hassan Al-Harbi",
    description: "Experience a unique desert retreat with this luxurious villa situated in the serene Al Marmoom desert. Enjoy exclusive amenities, including a private pool, expansive outdoor space, and breathtaking desert views.",
    // image: "/images/property (31).jpg",
     image: [
      img23,
      img24,
      img25,

  ]
  },
  {
    id: 8,
    name: "Exclusive Dubai Hills Estate Shop",
    location: "Dubai, Dubai Hills, Hills View",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 2800,
    distance: "2km",
    purpose: "Sale",
    number_of_beds: 5,
    number_of_bathrooms: 6,
    propertyType: "shop",
    contact: "+971 50 8901234",
    dimensions: "6500 sq ft",
    dealerImage: "",
    dd_MM_yy: "Year",
    dealer: "Layla Jassim",
    description: "Luxurious villa in the prestigious Dubai Hills Estate. Features include panoramic views of the Dubai skyline, modern design, and proximity to golf courses and retail centers. A perfect blend of luxury and convenience.",
    // image: "/images/property (32).jpg",
     image: [
      img26,
      img27,
      img28,
  ]
  },
  {
    id: 9,
    name: "Elegant Downtown Loft Office",
    location: "Dubai, Downtown Dubai, The Address Sky View",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 1700,
    distance: "1km",
    purpose: "Rent",
    number_of_beds: 1,
    number_of_bathrooms: 1,
    propertyType: "office",
    contact: "+971 50 9012345",
    dimensions: "800 sq ft",
    dealerImage: "",
    dd_MM_yy: "Day",
    dealer: "Ahmed Faris",
    description: "Stylish loft in The Address Sky View with stunning views of the Burj Khalifa. Features a sleek design, modern amenities, and direct access to luxury shopping and dining options.",
    // image: "/images/property (33).jpg",
     image: [
      img29,
      img30,
      img31,
  ]
  },
  {
    id: 10,
    name: "Opulent Palm Jumeirah Mansion Office",
    location: "Dubai, Palm Jumeirah, Frond N",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 5000,
    distance: "3km",
    purpose: "Sale",
    number_of_beds: 7,
    number_of_bathrooms: 8,
    propertyType: "office",
    contact: "+971 50 0123456",
    dimensions: "9000 sq ft",
    dealerImage: "",
    dd_MM_yy: "Week",
    dealer: "Rashid Al-Mutairi",
    description: "Grand mansion on Palm Jumeirah featuring luxurious living spaces, a private beach, and breathtaking views. Ideal for those seeking the pinnacle of luxury and privacy in Dubai.",
    // image: "/images/property (34).jpg",
     image: [
      img32,
      img33,
      img34,
  ]
  },
  {
    id: 11,
    name: "Luxury Villa in Downtown Dubai Office",
    location: "Dubai, Sheikh Mohammed bin Rashid Blvd, Downtown",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 2500,
    distance: "500m",
    purpose: "Rent",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "office",
    contact: "+971 50 123 4567",
    dimensions: "3000 sq ft",
    dealerImage: "",
    dd_MM_yy: "Month",
    dealer: "John",
    description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    // image: "/images/property (35).jpg"
     image: [
      img35,
      img36,
      img37,
  ]
  },
  {
    id: 12,
    name: "Modern Villa in Palm Jumeirah Office",
    location: "Dubai, Palm Jumeirah, Shoreline Apartments",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 3000,
    distance: "1km",
    purpose: "Rent",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "office",
    contact: "+971 50 234 5678",
    dimensions: "3200 sq ft",
    dealerImage: "",
    dd_MM_yy: "Year",
    dealer: "John",
    description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    // image: "/images/property (36).jpg"
     image: [
    img3,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
  ]
  },
  {
    id: 13,
    name: "Elegant Villa in Dubai Marina Penthouse",
    location: "Dubai, Dubai Marina, Marina Promenade",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 2800,
    distance: "800m",
    purpose: "Sale",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "penthouse",
    contact: "+971 50 345 6789",
    dimensions: "3100 sq ft",
    dealerImage: "",
    dd_MM_yy: "Day",
    dealer: "John",
    description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    // image: "/images/property (37).jpg"
     image: [
      img38,
      img39,
      img40,
      img41,
      img42,
  ]
  },
  {
    id: 14,
    name: "Spacious Villa in Jumeirah Beach Residence Penthouse",
    location: "Dubai, Jumeirah Beach Residence, Rimal",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 3200,
    distance: "1.5km",
    purpose: "Rent",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "penthouse",
    contact: "+971 50 456 7890",
    dimensions: "3500 sq ft",
    dealerImage: "",
    dd_MM_yy: "Week",
    dealer: "John",
    description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    // image: "/images/property (38).jpg"
     image: [
      img44,
      img45,
      img46,

  ]
  },
  {
    id: 15,
    name: "Chic Villa in Business Bay penthouse",
    location: "Dubai, Business Bay, Churchill Towers",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 2900,
    distance: "900m",
    purpose: "Sale",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "penthouse",
    contact: "+971 50 567 8901",
    dimensions: "3100 sq ft",
    dealerImage: "",
    dd_MM_yy: "Month",
    dealer: "John",
    description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    // image: "/images/property (39).jpg"
    image: [
      img1,
      img2,
      img3
    ]
  },
  {
    id: 16,
    name: "Elegant Villa in Downtown Dubai Penthouse",
    location: "Dubai, Sheikh Mohammed bin Rashid Blvd, Downtown",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 2500,
    distance: "500m",
    purpose: "Rent",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "penthouse",
    contact: "+971 50 123 4567",
    dimensions: "3000 sq ft",
    dealerImage: "/images/dealer1.jpg",
    dd_MM_yy: "Year",
    dealer: "John",
    description:
      "Experience luxury living in the heart of Downtown Dubai. This elegant villa boasts modern amenities and is just a short distance from iconic landmarks like the Dubai Mall and Burj Khalifa. Perfect for those seeking an upscale urban lifestyle with easy access to the city's vibrant atmosphere.",
    // image: "/images/property16.jpg",
    image: [
      img5,
      img4,
      img6
    ]
  },
  {
    id: 17,
    name: "Modern Villa in Palm Jumeirah appartments Mansion",
    location: "Dubai, Palm Jumeirah, Shoreline Apartments",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 3000,
    distance: "1km",
    purpose: "Sale",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "mansions",
    contact: "+971 50 234 5678",
    dimensions: "3000 sq ft",
    dealerImage: "/images/dealer2.jpg",
    dd_MM_yy: "Day",
    dealer: "John",
    description:
      "This modern villa on Palm Jumeirah offers stunning sea views and luxurious living. Featuring contemporary design and high-end finishes, it provides direct beach access and is ideal for those who value exclusivity and a premium lifestyle in one of Dubai's most prestigious locations.",
    // image: "/images/property17.jpg",
  
      image: [
        img10,
        img42,
        img30
      ]
  
  },
  {
    id: 18,
    name: "Luxury Villa in Dubai Marina Mansion",
    location: "Dubai, Dubai Marina, Marina Promenade",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 2800,
    distance: "800m",
    purpose: "Rent",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "mansions",
    contact: "+971 50 345 6789",
    dimensions: "3000 sq ft",
    dealerImage: "/images/dealer3.jpg",
    dd_MM_yy: "Week",
    dealer: "John",

    description:
      "Enjoy sophisticated urban living in this luxury villa located in Dubai Marina. With breathtaking views of the marina, modern architecture, and proximity to popular dining and entertainment options, this property offers both style and convenience in a vibrant setting.",
    // image: "/images/property18.jpg",
    image: [
      img6,
      img2,
      img42
    ]
  },
  {
    id: 19,
    name: "Spacious Villa in Jumeirah Beach Residence Mansion",
    location: "Dubai, Jumeirah Beach Residence, Rimal",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 3200,
    distance: "1.5km",
    purpose: "Sale",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "mansions",
    contact: "+971 50 456 7890",
    dimensions: "3000 sq ft",
    dealerImage: "/images/dealer4.jpg",
    dealer: "John",
    dd_MM_yy: "Month",
    description:
      "This spacious villa in Jumeirah Beach Residence offers a luxurious seaside lifestyle. With expansive rooms and premium amenities, it provides easy access to the beach, shopping, and dining. Perfect for those seeking a high-end coastal residence in Dubai.",
    // image: "/images/property19.jpg",
    image: [
      img4,
      img1,
      img9
    ]
  },
  {
    id: 20,
    name: "Chic Villa in Business Bay Mansions",
    location: "Dubai, Business Bay, Churchill Towers",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 2900,
    distance: "900m",
    purpose: "Rent",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "mansions",
    contact: "+971 50 567 8901",
    dimensions: "3000 sq ft",
    dealerImage: "/images/dealer5.jpg",
    dealer: "John",
    dd_MM_yy: "Year",
    description:
      "Located in Business Bay, this chic villa combines modern architecture with high-end finishes. The property is conveniently located near major business hubs and cultural landmarks, offering a stylish living space ideal for professionals and families alike.",
    // image: "/images/property20.jpg",
    image: [
      img6,
      img20,
      img39
    ]
  },
  {
    id: 21,
    name: "Luxurious Villa in Downtown Dubai Appartments",
    location: "Dubai, Sheikh Mohammed bin Rashid Blvd, Downtown",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 5000,
    distance: "500m",
    purpose: "Sale",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "appartments",
    contact: "+971 50 123 4567",
    dimensions: "3000 sq ft",
    dealerImage: "/images/dealer1.jpg",
    dealer: "John",
    dd_MM_yy: "Day",
    description:
      "Experience high-end urban living with this luxurious villa in Downtown Dubai. Enjoy modern amenities and breathtaking views of iconic landmarks such as Burj Khalifa and Dubai Mall. Perfect for those seeking a prestigious address with unparalleled convenience.",
    // image: "/images/property21.jpg",
    image: [
      img14,
      img21,
      img31
    ]
  },
  {
    id: 22,
    name: "Contemporary Villa in Palm Jumeirah Appartments",
    location: "Dubai, Palm Jumeirah, Shoreline Apartments",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 6000,
    distance: "1km",
    purpose: "Sale",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "appartments",
    contact: "+971 50 234 5678",
    dimensions: "3000 sq ft",
    dealerImage: "/images/dealer2.jpg",
    dealer: "John",
    dd_MM_yy: "Week",
    description:
      "This contemporary villa on Palm Jumeirah offers stunning sea views and luxury living. With modern design and top-tier finishes, it provides exclusive beach access and is ideal for those wanting a premium lifestyle in one of Dubai’s most coveted areas.",
    // image: "/images/property22.jpg",
    image: [
      img16,
      img25,
      img13
    ]
  },
  {
    id: 23,
    name: "Elegant Villa in Dubai Marina Appartments",
    location: "Dubai, Dubai Marina, Marina Promenade",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 5500,
    distance: "800m",
    purpose: "Sale",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "appartments",
    contact: "+971 50 345 6789",
    dimensions: "3000 sq ft",
    dealerImage: "/images/dealer3.jpg",
    dealer: "John",
    dd_MM_yy: "Month",
    description:
      "This elegant villa in Dubai Marina features sophisticated design and luxury amenities. Enjoy panoramic marina views and proximity to vibrant dining and entertainment options. A perfect choice for those seeking a stylish urban lifestyle in a dynamic setting.",
      image: [
        img12,
        img27,
        img33
      ]
  },
  {
    id: 24,
    name: "Chic Villa in Jumeirah Beach Residence Appartments",
    location: "Dubai, Jumeirah Beach Residence, Rimal",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 7000,
    distance: "1.5km",
    purpose: "Rent",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "appartments",
    contact: "+971 50 456 7890",
    dimensions: "3000 sq ft",
    dealerImage: "/images/dealer4.jpg",
    dealer: "John",
    dd_MM_yy: "Month",
    description:
      "This chic villa in Jumeirah Beach Residence offers an unparalleled coastal lifestyle. With expansive living spaces and premium amenities, you’ll enjoy direct beach access and a vibrant community atmosphere, ideal for those seeking a luxurious seaside retreat.",
    // image: "/images/property24.jpg",
    image: [
      img11,
      img23,
      img35
    ]
  },
  {
    id: 25,
    name: "Spacious Villa in Business Bay Villas",
    location: "Dubai, Business Bay, Churchill Towers",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 6500,
    distance: "900m",
    purpose: "Sale",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "villas",
    contact: "+971 50 567 8901",
    dimensions: "3000 sq ft",
    dd_MM_yy: "Month",
    dealerImage: "/images/dealer5.jpg",
    dealer: "John",
    description:
      "Located in the bustling Business Bay area, this spacious villa combines modern design with luxury. It provides easy access to business hubs and cultural landmarks, making it an excellent choice for professionals and families seeking a sophisticated urban residence.",
    // image: "/images/property25.jpg",
    image: [
      img6,
      img27,
      img35
    ]
  },
  {
    id: 26,
    name: "Spacious Villa in Business Bay Villas",
    location: "Dubai, Business Bay, Churchill Towers",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 6500,
    distance: "900m",
    purpose: "Sale",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "villas",
    contact: "+971 50 567 8901",
    dimensions: "3000 sq ft",
    dealerImage: "/images/dealer5.jpg",
    dealer: "John",
    dd_MM_yy: "Month",
    description:
      "Located in the bustling Business Bay area, this spacious villa combines modern design with luxury. It provides easy access to business hubs and cultural landmarks, making it an excellent choice for professionals and families seeking a sophisticated urban residence.",
    // image: "/images/property25.jpg",
    image: [
      img9,
      img27,
      img33
    ]
  },
  {
    id: 27,
    name: "Spacious Villa in Business Bay Villas",
    location: "Dubai, Business Bay, Churchill Towers",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 6500,
    distance: "900m",
    purpose: "Sale",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "villas",
    contact: "+971 50 567 8901",
    dimensions: "3000 sq ft",
    dealerImage: "/images/dealer5.jpg",
    dd_MM_yy: "Month",
    dealer: "John",
    description:
      "Located in the bustling Business Bay area, this spacious villa combines modern design with luxury. It provides easy access to business hubs and cultural landmarks, making it an excellent choice for professionals and families seeking a sophisticated urban residence.",
    // image: "/images/property25.jpg",
    image: [
      img1,
      img2,
      img3
    ]
  },
  {
    id: 28,
    name: "Spacious Villa in Business Bay Villas",
    location: "Dubai, Business Bay, Churchill Towers",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.68279754726!2d54.89784173913489!3d25.07628045434942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1725872698115!5m2!1sen!2s",
    price: 6500,
    distance: "900m",
    purpose: "Sale",
    number_of_beds: 3,
    number_of_bathrooms: 2,
    propertyType: "villas",
    contact: "+971 50 567 8901",
    dd_MM_yy: "Month",
    dimensions: "3000 sq ft",
    dealerImage: "/images/dealer5.jpg",
    dealer: "John",
    description:
      "Located in the bustling Business Bay area, this spacious villa combines modern design with luxury. It provides easy access to business hubs and cultural landmarks, making it an excellent choice for professionals and families seeking a sophisticated urban residence.",
    // image: "/images/property25.jpg",
    image: [
      img16,
      img12,
      img42
    ]
  },
];

export const testimonials = [
  {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  }, {
    id: 1,
    name: "Wabz Braize",
    role: "⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "⭐️⭐️⭐️⭐️⭐️",
    image: "/images/avatar.png",
    reviewText:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Corporis porro ex perferendis, nulla consectetur consequuntur odio qui adipisci harum earum dolor, eius accusantium quia praesentium.",
  },



];

export const feeds = [
  {
    id: 1,
    title: "Agency Is the capacity of an actor to act in a given.",
    date_posted: "january 29, 2023",
    image: "/images/blog/blog (1).jpg",
    // category: "architecture",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  {
    id: 2,
    title: "Agency Is the capacity of an actor to act in a given.",
    date_posted: "january 29, 2023",
    image: "/images/blog/blog (2).jpg",
    // category: "architecture",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  {
    id: 3,
    title: "Agency Is the capacity of an actor to act in a given.",
    date_posted: "january 29, 2023",
    image: "/images/blog/blog (3).jpg",
    // category: "interior",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  {
    id: 4,
    title: "Agency Is the capacity of an actor to act in a given.",
    date_posted: "january 29, 2023",
    image: "/images/blog/blog (4).jpg",
    // category: "architecture",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  {
    id: 5,
    title: "Agency Is the capacity of an actor to act in a given.",
    date_posted: "january 29, 2023",
    image: "/images/blog/blog (5).jpg",
    // category: "interior",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  {
    id: 6,
    title: "Agency Is the capacity of an actor to act in a given..",
    date_posted: "january 29, 2023",
    image: "/images/blog/blog (6).jpg",
    // category: "architecture",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  {
    id: 7,
    title: "Agency Is the capacity of an actor to act in a given..",
    date_posted: "january 29, 2023",
    image: "/images/blog/blog (7).jpg",
    // category: "architecture",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  {
    id: 8,
    title: "Agency Is the capacity of an actor to act in a given..",
    date_posted: "january 29, 2023",
    image: "/images/blog/blog (8).jpg",
    // category: "architecture",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
  {
    id: 9,
    title: "Agency Is the capacity of an actor to act in a given..",
    date_posted: "january 29, 2023",
    image: "/images/blog/blog (9).jpg",
    // category: "architecture",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Libero beatae sit voluptas dolorum vel eligendi consectetur alias in veniam nostrum reiciendis cum labore fugiat ut ipsum quidem earum eaque voluptatem enim possimus, est, saepe explicabo. Reiciendis veritatis maiores dolore quidem doloremque obcaecati quis commodi dolorum. Corporis ad provident officia aut iusto ea, voluptatibus architecto debitis pariatur qui! Nisi esse voluptas veniam, quia provident rerum iure quis quasi, odio quos dolores obcaecati nam nostrum nesciunt ipsum similique recusandae? Neque, animi? Voluptates facere rerum labore eos nulla! Ut, nesciunt facere fugit optio quaerat neque id fuga necessitatibus, pariatur eius tenetur? Esse ipsa numquam distinctio vero reprehenderit, libero architecto itaque, optio quibusdam cupiditate cum, deleniti ad. Sapiente, placeat voluptates a recusandae voluptatum cupiditate nulla vel ut. Eveniet eum obcaecati illo saepe, recusandae natus incidunt quas molestias maxime vel? Possimus perspiciatis, repellat maxime molestias quos perferendis! Impedit consectetur natus alias laudantium sunt ipsam nostrum! Eius quasi officiis, labore sunt itaque porro laudantium, suscipit ipsa dicta magni quibusdam totam? Exercitationem doloribus, debitis, eligendi facere odio a nesciunt nam suscipit quia ullam sequi, illum repudiandae! Hic nobis inventore maxime. Explicabo quod inventore excepturi adipisci provident quidem ab doloremque amet nostrum, ullam fugiat debitis eius aspernatur atque.",
    author: {
      name: "Wabweni Brian",
      avatar: "/images/avatar.png",
    },
  },
];

export const teamMembers = [
  {
    id: 1,
    name: "Wabz Braize",
    role: "Front-end developer",
    image: "/images/avatar.png",
  },
  {
    id: 2,
    name: "Ethan Hunt",
    role: "Backend  engineer",
    image: "/images/avatar-1.png",
  },
  {
    id: 3,
    name: "Raven Kent",
    role: "UI Designer",
    image: "/images/avatar-2.png",
  },
  {
    id: 4,
    name: "John Doe",
    role: "Web developer",
    image: "/images/avatar-3.png",
  },
  {
    id: 5,
    name: "John Doe",
    role: "Web developer",
    image: "/images/avatar-3.png",
  },
  {
    id: 6,
    name: "Raven Kent",
    role: "UI Designer",
    image: "/images/avatar-2.png",
  },
  {
    id: 7,
    name: "Ethan Hunt",
    role: "Backend  engineer",
    image: "/images/avatar-1.png",
  },
  {
    id: 8,
    name: "Wabz Braize",
    role: "Front-end developer",
    image: "/images/avatar.png",
  },
];

export const services = [
  {
    id: 1,
    name: "Sell Your Home",
    icon: <BiBarChart />,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 2,
    name: "Evalute Your Home",
    icon: <BiHomeAlt />,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 3,
    name: "Free Apraisal",
    icon: <BiFullscreen />,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  {
    id: 4,
    name: "Trusted Agency",
    icon: <BiShieldAlt2 />,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  },
  // 
];

// export const projects = [
//   {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   },
//   {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   }, {
//     id: 1,
//     name: "Apartment",
//     number: 20,
//     image: "/images/property (17).jpg",
//   },
//   {
//     id: 2,
//     name: "Office",
//     number: 23,
//     image: "/images/property (1).jpeg",
//   },
//   {
//     id: 3,
//     name: "Townhouse",
//     number: 36,
//     image: "/images/property (21).jpg",
//   },
//   {
//     id: 4,
//     name: "living room",
//     number: 12,
//     image: "/images/property (1).jpg",
//   },
//   {
//     id: 5,
//     name: "Real estate",
//     number: 36,
//     image: "/images/property (20).jpg",
//   },
//   {
//     id: 6,
//     name: "luxury apartment",
//     number: 14,
//     image: "/images/property (18).jpg",
//   },
// ];

export const brands = [
  "/images/brands/airbnb.png",
  "/images/brands/cisco.png",
  "/images/brands/ebay.png",
  "/images/brands/microsoft.png",
  "/images/brands/uber.png",
];

export const focus = [
  {
    id: 1,
    name: "Buy a New Home",
    icon: <BiHomeAlt />,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum commodi provident est ex similique.",
  },
  {
    id: 2,
    name: "Sell a Home",
    icon: <BiMoney />,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum commodi provident est ex similique.",
  },
  {
    id: 4,
    name: "Rent a Home",
    icon: <BiBuildings />,
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod earum commodi provident est ex similique.",
  },
];



export const propertyTypes = [
  {
    id: 1,
    name: "apartment",
    number: 20,
  },
  {
    id: 2,
    name: "house",
    number: 32,
  },
  {
    id: 3,
    name: "industrial",
    number: 25,
  },
  {
    id: 4,
    name: "office villa",
    number: 62,
  },
  {
    id: 5,
    name: "luxury home",
    number: 22,
  },
  {
    id: 6,
    name: "land",
    number: 15,
  },
  {
    id: 7,
    name: "Rental",
    number: 10,
  },
  {
    id: 8,
    name: "studio",
    number: 17,
  },
];
export const priceRanges = [
  {
    id: 1,
    name: "low Budget",
    range: "$100k - 300k",
  },
  {
    id: 2,
    name: "medium budget",
    range: "300k - 500k",
  },
  {
    id: 3,
    name: "high budget",
    range: "500k - 1M",
  },
];

export const socials = [
  <FaFacebook />,
  <FaTwitter />,
  <FaInstagram />,
  <FaLinkedin />,
  <FaBehance />,
  <FaDribbble />,
  <FaYoutube />,
  <FaVimeo />,
];

export const ratings = [
  {
    id: 1,
    image: "/images/property (14).jpg",
    rating: 4.3,
    name: "Luxury mansion in Oregon",
    price: 100,
  },
  {
    id: 2,
    image: "/images/property (26).jpg",
    rating: 4.3,
    name: "Luxury mansion in Oregon",
    price: 100,
  },
  {
    id: 3,
    image: "/images/property (16).jpg",
    rating: 4.3,
    name: "Luxury mansion in Oregon",
    price: 100,
  },
  {
    id: 4,
    image: "/images/property (18).jpg",
    rating: 4.3,
    name: "Luxury mansion in Oregon",
    price: 100,
  },
];

export const tags = [
  "Popular",
  "Luxury Villa",
  "Apartment",
  "Interior Design",
  "Architect",
  "Condor",
  "Home",
];

export const faqs = [
  {
    id: 1,
    question: "How can we help?",
    response:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi, molestias ipsam dolorum laudantium corrupti optio cupiditate libero odit cum, labore ab sint officiis reiciendis quisquam perferendis, facilis facere iste inventore architecto blanditiis suscipit. Voluptatibus.",
  },
  {
    id: 2,
    question: "How can I make refund from your website?",
    response:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi, molestias ipsam dolorum laudantium corrupti optio cupiditate libero odit cum, labore ab sint officiis reiciendis quisquam perferendis, facilis facere iste inventore architecto blanditiis suscipit. Voluptatibus.",
  },
  {
    id: 3,
    question: "Do you store any of my information?",
    response:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi, molestias ipsam dolorum laudantium corrupti optio cupiditate libero odit cum, labore ab sint officiis reiciendis quisquam perferendis, facilis facere iste inventore architecto blanditiis suscipit. Voluptatibus.",
  },
  {
    id: 4,
    question: "Should I talk to the bank before booking a home?",
    response:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi, molestias ipsam dolorum laudantium corrupti optio cupiditate libero odit cum, labore ab sint officiis reiciendis quisquam perferendis, facilis facere iste inventore architecto blanditiis suscipit. Voluptatibus.",
  },
  {
    id: 5,
    question: "How do i make payments using my credit card?",
    response:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi, molestias ipsam dolorum laudantium corrupti optio cupiditate libero odit cum, labore ab sint officiis reiciendis quisquam perferendis, facilis facere iste inventore architecto blanditiis suscipit. Voluptatibus.",
  },
  {
    id: 6,
    question: "How do i link multiple accounts with my phone?",
    response:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iusto nisi, molestias ipsam dolorum laudantium corrupti optio cupiditate libero odit cum, labore ab sint officiis reiciendis quisquam perferendis, facilis facere iste inventore architecto blanditiis suscipit. Voluptatibus.",
  },
];






export const categories = [
  {
    id: 1,
    name: "Apartments",
    number: 20,
    cat: "appartments",
    image: img10,
  },
  {
    id: 2,
    name: "Offices",
    number: 23,
    cat: "office",
    image: "/images/property.jpg",
  },
  {
    id: 3,
    name: "Pent house",
    number: 36,
    cat: "penthouse",
    image: "/images/pent-house.jpg",
  },
  {
    id: 4,
    name: "Villas",
    number: 12,
    cat: "villas",
    image: "/images/property (10).jpg",
  },

  {
    id: 5,
    name: "Condos",
    number: 14,
    cat: "condos",
    image: "/images/property (18).jpg",
  },
  {
    id: 6,
    name: "Mansions",
    number: 14,
    cat: "mansions",
    image: "/images/property (28).jpg",
  },
  {
    id: 7,
    name: "Shops",
    number: 14,
    cat: "shop",
    image: "/images/shop.jpg",
  }, 
];