import { BiBed, BiMap, BiMapAlt, BiTab } from "react-icons/bi";
import { Link } from "react-router-dom";
import WOW from 'wow.js';
import { useEffect, useState } from "react";
import CardLabels from "./CardLabels";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { FaArrowRight , FaArrowLeft  } from "react-icons/fa";
const SingleProductCardFullWidth = ({
  name,
  location,
  price,
  distance,
  purpose,
  number_of_beds,
  number_of_bathrooms,
  dimensions,
  image,
  description,
  textLength = 180,
  showLabels,
  dd_MM_yy,
  dealer,
  id,
  ...data
}) => {
  useEffect(() => {
    const wow = new WOW({
      live: false,
      offset: 100,
      mobile: true
    });
    wow.init();
  }, []);




  const [currentSlide, setCurrentSlide] = useState(0);


  const add = () => {
    setCurrentSlide(prevSlide => (prevSlide + 1) % image.length);
  };

  const subtract = () => {
    setCurrentSlide(prevSlide => (prevSlide - 1 + image.length) % image.length);
  };

  return (
    <div className="relative grid grid-cols-1 gap-3 mt-3 overflow-hidden border rounded-lg shadow-light sm:grid-cols-3 md:grid-cols-4 dark:border-card-dark group wow fadeInUp" data-wow-delay="0.5s">
      <div className="sm:col-span-1">
        <div className="group relative overflow-hidden h-full">

        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={125}
          totalSlides={image.length}
          className="w-full h-[250px]"
        >
          <Slider
            onAfterSlide={index => setCurrentSlide(index)}
          >
            {image.map((imgSrc, index) => (
              <Slide key={index}>
                <img
                  src={imgSrc}
                  alt={`${name} image ${index + 1}`}
                  className="object-cover w-full h-[250px] transition-transform duration-300"
                />
              </Slide>
            ))}
          </Slider>
          <ButtonBack onClick={subtract}  className="absolute top-1/2 left-0 transform -translate-y-1/2 h-12 w-12 flex items-center justify-center text-white text-3xl rounded-full">
            <FaArrowLeft />
          </ButtonBack>
          <ButtonNext onClick={add}  className="absolute top-1/2 right-0 transform -translate-y-1/2 h-12 w-12 flex items-center justify-center text-white text-3xl rounded-full">
            <FaArrowRight />
          </ButtonNext>
          <div className="absolute top-0 left-0 w-full flex items-center justify-end">
            <p className="text-white bg-black/50 p-1 rounded-md">{currentSlide + 1} / {image.length}</p>
          </div>
        </CarouselProvider>
          <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black/80 to-transparent p-2 text-white">
            <div className="flex items-center gap-x-2">
              <BiMap />
              <p>{location}</p>
            </div>
          </div>
        </div>
        {!showLabels && <CardLabels purpose={purpose} distance={distance} />}
      </div>
      <div className="sm:col-span-2 md:col-span-3">
        <div className="p-3">
          <Link to={`/property/${id}`} className="group-hover:text-primary transition-colors duration-300">
            <h1 className="text-lg font-bold capitalize">{name}</h1>
          </Link>
          <p className="mt-2">{`${description.slice(0, textLength)}...`}</p>
          <div className="flex justify-between mt-3">
            <div className="flex items-center gap-x-2">
              <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
                <BiBed />
              </div>
              <p className="text-sm">{number_of_beds} Beds</p>
            </div>
            <div className="flex items-center gap-x-2">
              <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
                <BiTab />
              </div>
              <p className="text-sm">{number_of_bathrooms} Bathrooms</p>
            </div>
            <div className="flex items-center gap-x-2">
              <div className="icon-box !w-7 !h-7 bg-primary/20 hover:!bg-primary/40 text-primary">
                <BiMapAlt />
              </div>
              <p className="text-sm">{dimensions}</p>
            </div>
          </div>
          <div className="mt-4 flex justify-between items-center">
            <h1 className="text-2xl  font-sans  font-extrabold">AED <span className="text-primary">{price}</span> /{dd_MM_yy}</h1>
            <Link to={`/property/${id}`} className="btn btn-primary">Details</Link>
          </div>
          <h6 className="text-sm font-semibold mt-2">
          <img className="h-7 w-7 rounded-full inline-block mr-3" src={image} alt={dealer} />
          {dealer}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default SingleProductCardFullWidth;
